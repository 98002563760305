import axios from "axios";

import { MessageError } from "../stores";
import { refreshToken } from "../api/authUtils";

const instance = axios.create({
  // baseURL: process.env.NEXT_PUBLIC_BASE_API_URL
  baseURL: "https://pymnts.exchange/api/v1/",
});

export const addToken = (token) => {
  instance.interceptors.request.use(async (request) => {
    request.headers["Authorization"] = `Bearer ${token}`;
    return request;
  });
};
export const resetToken = () => {
  instance.interceptors.request.use(async (request) => {
    return request;
  });
};
instance.interceptors.response.use(
  (response) => {
    if (response.status === 200 && response.data?.errors?.length) {
      throw new MessageError(response.data.errors[0]);
    }

    return response;
  },
  async (error) => {
    const { response } = error;
    const originalConfig = error.config;

    if (response?.status === 400 && response.data?.errors?.length) {
      throw new MessageError(response.data.errors[0]);
    }
    if (response?.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;
      refreshToken();
      return instance(originalConfig);
    }
    throw error;
  }
);

export default instance;
